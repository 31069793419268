import axios from "axios";
import queryString from "query-string";

export default {
    namespaced: true,
    state: () => ({
        stocks: [],
        isLoading: false,
    }),
    mutations: {
        setStocks(state, stocks) {
            state.stocks = stocks;
        },
    },
    actions: {
        async getStocks({ commit, state }) {
            state.isLoading = true;
            try {
                const res = await axios.get("/api/v1/Stock", {
                    headers: {
                        "Authorization": "3e72865f43f6aea1c0fab4f77004f49f006962ff"
                    },
                });
                if (res.status === 200) {
                    commit("setStocks", res.data.results);
                }

            } catch (error) { }
            state.isLoading = false
        },
        
        /*  */
    },
    getters: {
        stocks(state) {
            return state.stocks;
        },
        isLoading(state) {
            return state.isLoading;
        },
    },
};
