import axios from "axios";


export default {
    namespaced: true,
    state: () => ({
        payment: [],
        isLoading: false,
    }),
    mutations: {
        setPayment(state, payment) {
            state.payment = payment;
        },
    },
    actions: {
        async getPayment({
            commit,
            state
        }) {
            state.isLoading = true;
            try {
                const res = await axios.get("/api/v1/Payment/", {
                    headers: {
                        "Authorization": "8f3649541a77afc6dba538d0589424178cab4e44"
                    },
                });
                if (res.status === 200) {
                    commit("setPayment", res.data.results);
                }

            } catch (error) {}
            state.isLoading = false
        },
        async addPayment({
            dispatch
        }, payment) {
            await axios("/api/v1/Payment/", {
                method: "POST",
                headers: {
                    "Authorization": "8f3649541a77afc6dba538d0589424178cab4e44"
                },
                data: payment,
            });
            dispatch("getPayment");
        },
        async editpayment({
            dispatch
        }, payment) {
            await axios(`/api/v1/Payment/${payment.id}/`, {
                method: "PUT",
                headers: {
                    "Authorization": "8f3649541a77afc6dba538d0589424178cab4e44"
                },
                data: payment,
            });
            dispatch("getPayment");
        },
    },
    getters: {
        payment(state) {
            return state.payment;
        },
        isLoading(state) {
            return state.isLoading;
        },
    },
};