<template>
  <div class="table">

    <div class="table-wrapper">
      <table class="table">
        <thead>
          <tr>
            <th v-for="h in head" :key="h">{{ h }}</th>
          </tr>
        </thead>
        <tbody>
          <slot />
        </tbody>
      </table>
    </div>
    <slot name="pagination"></slot>
  </div>
</template>

<script>
export default {
  props: ["head"],
};
</script>

<style></style>
