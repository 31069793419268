import Vue from "vue";
import VueRouter from "vue-router";
import store from "../store/";



Vue.use(VueRouter);


const routes = [

  {
    path: "/login",
    name: "login",
    component: () => import("@/views/Login.vue"),
    meta: {
      layout: "empty",
      auth: false,

    },
  },

  {
    beforeEnter: (to, from, next) => {
      if (hasValidToken()) {
        next();
      } else {
        next('/login');
      }
    },
    path: "/",
    name: "Home",
    component: () => import("@/views/Home.vue"),
    meta: {
      requiresLogged: true,
      layout: "main",

    },
  },

  {
    beforeEnter: (to, from, next) => {
      if (hasValidToken()) {
        next();
      } else {
        next('/login');
      }
    },
    path: "/lidi",
    name: "lidi",
    component: () => import("@/views/Lidi.vue"),
    meta: {
      requiresLogged: true,
      layout: "main",
    },
  },
  {
    beforeEnter: (to, from, next) => {
      if (hasValidToken()) {
        next();
      } else {
        next('/login');
      }
    },
    path: "/sales",
    name: "sales",
    component: () => import("@/views/Sales.vue"),
    meta: {
      requiresLogged: true,
      layout: "main",
    },
  },
  {
    beforeEnter: (to, from, next) => {
      if (hasValidToken()) {
        next();
      } else {
        next('/login');
      }
    },
    path: "/calls",
    name: "calls",
    component: () => import("@/views/Calls.vue"),
    meta: {
      requiresLogged: true,
      layout: "main",
    },
  },
  {
    beforeEnter: (to, from, next) => {
      if (hasValidToken()) {
        next();
      } else {
        next('/login');
      }
    },
    path: "/calendar",
    name: "calendar",
    component: () => import("@/views/Calendar.vue"),
    meta: {
      requiresLogged: true,
      layout: "main",
    },
  },
  {
    beforeEnter: (to, from, next) => {
      if (hasValidToken()) {
        next();
      } else {
        next('/login');
      }
    },
    path: "/tasks",
    name: "tasks",
    component: () => import("@/views/Tasks.vue"),
    meta: {
      requiresLogged: true,
      layout: "main",
    },
  },
  {
    beforeEnter: (to, from, next) => {
      if (hasValidToken()) {
        next();
      } else {
        next('/login');
      }
    },
    path: "/delivery",
    name: "delivery",
    component: () => import("@/views/Delivery.vue"),
    meta: {
      requiresLogged: true,
      layout: "main",
    },
  },
  {
    beforeEnter: (to, from, next) => {
      if (hasValidToken()) {
        next();
      } else {
        next('/login');
      }
    },
    path: "/notifications",
    name: "notifications",
    component: () => import("@/views/Notifications.vue"),
    meta: {
      requiresLogged: true,
      layout: "main",
    },
  },
  {
    beforeEnter: (to, from, next) => {
      if (hasValidToken()) {
        next();
      } else {
        next('/login');
      }
    },
    path: "/messages",
    name: "messages",
    component: () => import("@/views/Messages.vue"),
    meta: {
      requiresLogged: true,
      layout: "main",
    },
  },
  {
    beforeEnter: (to, from, next) => {
      if (hasValidToken()) {
        next();
      } else {
        next('/login');
      }
    },
    path: "/reports",
    name: "reports",
    component: () => import("@/views/Reports.vue"),
    meta: {
      requiresLogged: true,
      layout: "main",
    },
  },
  {
    beforeEnter: (to, from, next) => {
      if (hasValidToken()) {
        next();
      } else {
        next('/login');
      }
    },
    path: "/reports-branches",
    name: "reports-branches",
    component: () => import("@/views/ReportsBranches.vue"),
    meta: {
      requiresLogged: true,
      layout: "main",
    },
  },
  {
    beforeEnter: (to, from, next) => {
      if (hasValidToken()) {
        next();
      } else {
        next('/login');
      }
    },
    path: "/reports-region",
    name: "reports-region",
    component: () => import("@/views/ReportsRegion.vue"),
    meta: {
      requiresLogged: true,
      layout: "main",
    },
  },
  {
    beforeEnter: (to, from, next) => {
      if (hasValidToken()) {
        next();
      } else {
        next('/login');
      }
    },
    path: "/reports-staff",
    name: "reports-staff",
    component: () => import("@/views/ReportsStaff.vue"),
    meta: {
      requiresLogged: true,
      layout: "main",
    },
  },
  {
    beforeEnter: (to, from, next) => {
      if (hasValidToken()) {
        next();
      } else {
        next('/login');
      }
    },
    path: "/settings",
    name: "settings",
    component: () => import("@/views/Settings.vue"),
    meta: {
      requiresLogged: true,
      layout: "main",
    },
  },
  {
    beforeEnter: (to, from, next) => {
      if (hasValidToken()) {
        next();
      } else {
        next('/login');
      }
    },
    path: "/branches",
    name: "branches",
    component: () => import("@/views/Branches.vue"),
    meta: {
      requiresLogged: true,
      layout: "main",
    },
  },
  {
    beforeEnter: (to, from, next) => {
      if (hasValidToken()) {
        next();
      } else {
        next('/login');
      }
    },
    path: "/departments",
    name: "departments",
    component: () => import("@/views/Departments.vue"),
    meta: {
      requiresLogged: true,
      layout: "main",
    },
  },
  {
    beforeEnter: (to, from, next) => {
      if (hasValidToken()) {
        next();
      } else {
        next('/login');
      }
    },
    path: "/staff",
    name: "staff",
    component: () => import("@/views/Staff.vue"),
    meta: {
      requiresLogged: true,
      layout: "main",
    },
  },
  {
    beforeEnter: (to, from, next) => {
      if (hasValidToken()) {
        next();
      } else {
        next('/login');
      }
    },
    path: "/roles",
    name: "roles",
    component: () => import("@/views/Roles.vue"),
    meta: {
      requiresLogged: true,
      layout: "main",
    },
  },
  {
    beforeEnter: (to, from, next) => {
      if (hasValidToken()) {
        next();
      } else {
        next('/login');
      }
    },
    path: "/telefoniya",
    name: "telefoniya",
    component: () => import("@/views/Telefoniya.vue"),
    meta: {
      requiresLogged: true,
      layout: "main",
    },
  },
  {
    beforeEnter: (to, from, next) => {
      if (hasValidToken()) {
        next();
      } else {
        next('/login');
      }
    },
    path: "/social",
    name: "social",
    component: () => import("@/views/Social.vue"),
    meta: {
      requiresLogged: true,
      layout: "main",
    },
  }, {
    beforeEnter: (to, from, next) => {
      if (hasValidToken()) {
        next();
      } else {
        next('/login');
      }
    },
    path: "/status-lids",
    name: "status-lids",
    component: () => import("@/views/StatusLids.vue"),
    meta: {
      requiresLogged: true,
      layout: "main",
    },
  },
  {
    beforeEnter: (to, from, next) => {
      if (hasValidToken()) {
        next();
      } else {
        next('/login');
      }
    },
    path: "/status-sales",
    name: "status-sales",
    component: () => import("@/views/StatusSales.vue"),
    meta: {
      requiresLogged: true,
      layout: "main",
    },
  },
  {
    beforeEnter: (to, from, next) => {
      if (hasValidToken()) {
        next();
      } else {
        next('/login');
      }
    },
    path: "/status-delivery",
    name: "status-delivery",
    component: () => import("@/views/StatusDelivery.vue"),
    meta: {
      requiresLogged: true,
      layout: "main",
    },
  },
  {
    beforeEnter: (to, from, next) => {
      if (hasValidToken()) {
        next();
      } else {
        next('/login');
      }
    },
    path: "/status-tasks",
    name: "status-tasks",
    component: () => import("@/views/StatusTasks.vue"),
    meta: {
      requiresLogged: true,
      layout: "main",
    },
  },
  {
    beforeEnter: (to, from, next) => {
      if (hasValidToken()) {
        next();
      } else {
        next('/login');
      }
    },
    path: "/sources",
    name: "sources",
    component: () => import("@/views/Sources.vue"),
    meta: {
      requiresLogged: true,
      layout: "main",
    },
  },
  {
    beforeEnter: (to, from, next) => {
      if (hasValidToken()) {
        next();
      } else {
        next('/login');
      }
    },
    path: "/profile",
    name: "profile",
    component: () => import("@/views/Profile.vue"),
    meta: {
      requiresLogged: true,
      layout: "main",
    },
  },
  {
    beforeEnter: (to, from, next) => {
      if (hasValidToken()) {
        next();
      } else {
        next('/login');
      }
    },
    path: "/products",
    name: "products",
    component: () => import("@/views/Products.vue"),
    meta: {
      requiresLogged: true,
      layout: "main",
    },
  },
  {
    beforeEnter: (to, from, next) => {
      if (hasValidToken()) {
        next();
      } else {
        next('/login');
      }
    },
    path: "/presentation",
    name: "presentation",
    component: () => import("@/views/Presentation.vue"),
    meta: {
      requiresLogged: true,
      layout: "main",
    },
  },
];


const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
  store,
  linkActiveClass: "active",
  linkExactActiveClass: "active",
});


function hasValidToken() {
  const token = localStorage.getItem('token');
  return !!token;
}

export default router;