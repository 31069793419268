import lids from "./modules/lids.module";
import auth from "./modules/auth.module";
import country from "./modules/country.module";
import istochnik from "./modules/istochnik.module";
import status from "./modules/status.module";
import user from "./modules/user.module";
import sales from "./modules/sales.module";
import tasks from "./modules/tasks.module";
import city from "./modules/city.module";
import branches from "./modules/branches.module";
import employee from "./modules/employee.module";
import departments from "./modules/departments.module";
import stocks from "./modules/stocks.module";
import payments from "./modules/payments.module";
import roles from "./modules/roles.module";
import unit from "./modules/unit.module";
import manufacturer from "./modules/manufacturer.module";
import delivery from "./modules/delivery.module";
import products from "./modules/products.module";
import Vue from "vue";
import Vuex from "vuex";


Vue.use(Vuex);

export default new Vuex.Store({
  modules: {
    lids,
    auth,
    sales,
    country,
    istochnik,
    user,
    status,
    tasks,
    city,
    employee,
    branches,
    departments,
    roles,
    unit,
    manufacturer,
    payments,
    delivery,
    products,
    stocks
  },
  state: {
    currentTime: null,
    interval: null,
    s: {
      isFilterOpen: false,
      isAddOpen: false,
      isEditOpen: false,
    },
    details: {},
  },
  getters: {
    s(state) {
      return state.s;
    },
    details(state) {
      return state.details;
    },
  },
  mutations: {
    SET_DATE(state, date) {
      state.currentTime = date;
    },
    CLEAR_INTERVAL(state) {
      clearInterval(state.interval);
    },
    SET_DETAILS(state, details) {
      state.details = details;
    },
  },
  actions: {
    renderDate({
      commit,
      state
    }) {
      const zeros = (i) => {
        return i < 10 ? "0" + i : i;
      };
      const setDate = (date) => {
        return `${zeros(date.getHours())}:${zeros(date.getMinutes())}:${zeros(
          date.getSeconds()
        )}`;
      };
      commit("SET_DATE", setDate(new Date()));
      state.interval = setInterval(() => {
        commit("SET_DATE", setDate(new Date()));
      }, 1000);
    },
  },
});