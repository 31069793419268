import Vue from "vue";
import "./assets/scss/main.scss";
import "./assets/css/all.min.css";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import i18n from "./i18n";
import "./interceptors/axios";
import VueApexCharts from "vue-apexcharts";
import EmptyComponentToggle from "./mixins/EmptyComponentToggle";
import VueCompositionAPI from "@vue/composition-api";


Vue.config.productionTip = false;



const settings = {
  apiKey: process.env.VUE_APP_YANDEX_KEY,
  lang: "ru_RU",
  coordorder: "latlong",
  enterprise: false,
  version: "2.1",
};




Vue.use(VueCompositionAPI);
Vue.component("apexchart", VueApexCharts);
Vue.mixin(EmptyComponentToggle);

new Vue({
  router,
  store,
  i18n,
  render: (h) => h(App),
}).$mount("#app");
