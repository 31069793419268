import axios from "axios";


export default {
    namespaced: true,
    state: () => ({
        status: [],
        isLoading: false,
    }),
    mutations: {
        setStatus(state, status) {
            state.status = status;
        },
    },
    actions: {
        async getStatus({
            commit,
            state
        }) {
            state.isLoading = true;
            try {
                const res = await axios.get("/api/v1/Status_Lids/", {
                    headers: {
                        "Authorization": "8f3649541a77afc6dba538d0589424178cab4e44"
                    },
                });
                if (res.status === 200) {
                    commit("setStatus", res.data.results);
                }

            } catch (error) {}
            state.isLoading = false
        },

    },
    getters: {
        status(state) {
            return state.status;
        },
        isLoading(state) {
            return state.isLoading;
        },
    },
};