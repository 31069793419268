<template>
  <div class="app-main-layout">
    <the-navbar
      @makeFalse="makeFalse"
      @removeElement="removeElement"
      @openSideNav="openSideNav"
    />
    <the-sidebar :dropdownList="dropdownList" @clickHandler="clickHandler" />

    <div class="app-page">
      <router-view />
    </div>

    <navMessage :openedSideNav="openedSideNav" />
  </div>
</template>

<script>
import navMessage from "@/components/navMessage.vue";
import TheNavbar from "@/components/TheNavbar.vue";
import TheSidebar from "@/components/TheSidebar.vue";
import Teleport from "vue2-teleport";
import store from "../store";

export default {
  components: { TheNavbar, TheSidebar, navMessage, Teleport },
  data() {
    return {
      dropdownList: [
        { item: false },
        { item: false },
        { item: false },
        { item: false },
        { item: false },
        { item: false },
        { item: false },
      ],
      openedSideNav: false,
    };
  },
  methods: {
    makeFalse() {
      this.dropdownList.forEach((list) => (list.item = false));
    },
    clickHandler(idx) {
      if (!document.body.classList.contains("small-sidenav")) {
        this.dropdownList[idx].item = !this.dropdownList[idx].item;
      } else {
        this.makeFalse();
      }
    },
    removeElement() {
      document.body.classList.remove("mobile-sidenav");
    },
    openSideNav() {
      this.openedSideNav = !this.openedSideNav;
    },
    beforeCreate() {
      this.$store.commit("initializeStore");

      const token = this.$store.state.token;

      if (token) {
        axios.defaults.headers.common["Authorization"] = "Token" + token;
      } else {
        axios.defaults.headers.common["Authorization"] = "";
      }
    },
  },
};
</script>
