import axios from "axios";
import queryString from "query-string";

export default {
  namespaced: true,
  state: () => ({
    listSales: [],
    lidsSales: [],
    canbanSales: [],
    isLoading: false,
    isLoading: false,
    filteredProducts: [],
    groupedSales: [],
    status: []
  }),
  mutations: {
    setLidsSales(state, lidsSales) {
      state.lidsSales = lidsSales;
    },
    setListSales(state, listSales) {
      state.listSales = listSales;
    },
    setCanbanSales(state, canbanSales) {
      state.canbanSales = canbanSales;
    },
    setFilteredProducts(state, products) {
      state.filteredProducts = products;
      state.filteredProducts = groupedSales;
    },
    setStatus(state, status) {
      state.status = status;
    },
    groupSalesByStatus(state) {
      state.groupedSales = state.status.reduce((result, status) => {
        const statusName = status.id;

        if (statusName) {
          result[statusName] = state.canbanSales.filter(lid => lid.status === status.id);
        }

        return result;
      }, {});
    },

  },
  actions: {
    async getStatus({
      commit,
      state
    }) {
      state.isLoading = true;
      try {
        const res = await axios.get("/api/v1/Status_Sales/", {
          headers: {
            "Authorization": "8f3649541a77afc6dba538d0589424178cab4e44"
          },
        });
        if (res.status === 200) {
          commit("setStatus", res.data.results);
          commit("groupSalesByStatus");
        }

      } catch (error) { }
      state.isLoading = false
    },
    async addStatus({
      dispatch
    }, Sales) {
      await axios("/api/v1/Status_Sales/", {
        method: "POST",
        headers: {
          "Authorization": "8f3649541a77afc6dba538d0589424178cab4e44"
        },
        data: Sales,
      });
      dispatch("getStatus");
    },
    async updateStatus({
      dispatch
    }, status) {
      await axios(`/api/v1/Status_Sales/${status.id}/`, {
        method: "PUT",
        headers: {
          "Authorization": "8f3649541a77afc6dba538d0589424178cab4e44"
        },
        data: status,
      });
      await dispatch("getStatus");
      location.reload()
    },
    async throwToTrashStatus({
      dispatch
    }, status) {
      await axios(`/api/v1/Status_Sales/${status}/`, {
        method: "DELETE",
        headers: {
          "Authorization": "8f3649541a77afc6dba538d0589424178cab4e44"
        },
        data: status,
      });

      await dispatch("getStatus");
    },
    async getListSales({
      commit,
      dispatch,
      state
    }) {
      state.isLoading = true;
      try {
        await dispatch("getStatus"); // Wait for getStatus to complete

        const res = await axios.get("/api/v1/Sales_List/", {
          headers: {
            "Authorization": "8f3649541a77afc6dba538d0589424178cab4e44"
          },
        });

        if (res.status === 200) {
          commit("setListSales", res.data);


        }
      } catch (error) {
        console.error(error);
      }
      state.isLoading = false;
    },
    async getLidsSales({
      commit,
      dispatch,
      state
    }, lid) {
      state.isLoading = true;
      try {
        await dispatch("getStatus"); // Wait for getStatus to complete

        const res = await axios.get(`/api/v1/Sales_List/?lid=${lid}`, {
          headers: {
            "Authorization": "8f3649541a77afc6dba538d0589424178cab4e44"
          },
        });

        if (res.status === 200) {
          commit("setLidsSales", res.data);


        }
      } catch (error) {
        console.error(error);
      }
      state.isLoading = false;
    },
    async getPaginationSales({
      commit,
      dispatch,
      state
    }, page) {
      state.isLoading = true;
      try {
        await dispatch("getStatus"); // Wait for getStatus to complete

        const res = await axios.get(`/api/v1/Sales_List/?page=${page}`, {
          headers: {
            "Authorization": "8f3649541a77afc6dba538d0589424178cab4e44"
          },
        });

        if (res.status === 200) {
          commit("setListSales", res.data);


        }
      } catch (error) {
        console.error(error);
      }
      state.isLoading = false;
    },
    async getСanbanSales({
      commit,
      dispatch,
      state
    }) {
      state.isLoading = true;
      try {
        await dispatch("getStatus"); // Wait for getStatus to complete

        const res = await axios.get("/api/v1/Sales_Kanban/?limit=10&offset=10&page_size=100000000000", {
          headers: {
            "Authorization": "8f3649541a77afc6dba538d0589424178cab4e44"
          },
        });

        if (res.status === 200) {
          commit("setCanbanSales", res.data.results);
          commit("groupSalesByStatus");

        }
      } catch (error) {
        console.error(error);
      }
      state.isLoading = false;
    },
    async filterSales({
      commit,
      state
    }, lid) {
      state.isLoading = true;

      const queryParams = {};

      if (lid.name) {
        queryParams.name = lid.name.toString();
      }

      if (lid.job) {
        queryParams.phone = lid.phone.toString();
      }

      if (lid.comment) {
        queryParams.comment = lid.comment.toString();
      }
      if (lid.company) {
        queryParams.company = lid.company.toString();
      }

      if (lid.job) {
        queryParams.job = lid.job.toString();
      }

      if (lid.address) {
        queryParams.address = lid.address.toString();
      }
      if (lid.web) {
        queryParams.web = lid.web.toString();
      }

      if (lid.email) {
        queryParams.email = lid.email.toString();
      }



      try {
        const query = queryString.stringify(queryParams);
        const res = await axios.get(`/api/v1/Sales_List/?${query}`, {
          headers: {
            Authorization: "8f3649541a77afc6dba538d0589424178cab4e44",
          },
        });

        if (res.status === 200) {
          commit("setListSales", res.data);
        }
      } catch (error) {
        console.error(error);
      }
      state.isLoading = false;
    },
    async addSales({ dispatch }, Sales) {
      try {
        const response = await axios.post("/api/v1/Sales_List/", Sales, {
          headers: {
            Authorization: "8f3649541a77afc6dba538d0589424178cab4e44",
          }
        });

        dispatch("getSales");
        return response.data;
      } catch (error) {
        // Handle error
        console.error("Error adding sales:", error);
        throw error; // Rethrow error for the calling function to handle
      }
    },



    async updateSales({
      dispatch
    }, lid) {
      await axios(`/api/v1/Sales_List/${lid.id}/`, {
        method: "PUT",
        headers: {
          "Authorization": "8f3649541a77afc6dba538d0589424178cab4e44"
        },
        data: lid,
      });
      // await dispatch("getSales");
      location.reload()
    },



    async deleteSales({
      dispatch, commit
    }, saleId) {
      await axios(`/api/v1/Sales_Kanban/${saleId}/`, {
        method: "DELETE",
        headers: {
          "Authorization": "8f3649541a77afc6dba538d0589424178cab4e44"
        },
        data: saleId,
      });
      commit("setListSales", data);
      await dispatch("getSales");
    },


  },
  getters: {
    status(state) {
      return state.status;
    },
    listSales(state) {
      return state.listSales;
    },
    lidsSales(state) {
      return state.lidsSales;
    },
    canbanSales(state) {
      return state.canbanSales;
    },
    searchedSales(state) {
      return state.searchedSales;
    },
    groupedSales(state) {
      return state.groupedSales;
    },
    isLoading(state) {
      return state.isLoading;
    },

    filteredProducts: (state) => state.filteredProducts,
  },
};