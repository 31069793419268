import axios from "axios";


export default {
    namespaced: true,
    state: () => ({
        city: [],
        isLoading: false,
    }),
    mutations: {
        setCity(state, city) {
            state.city = city;
        },
    },
    actions: {
        async getCity({
            commit,
            state
        }) {
            state.isLoading = true;
            try {
                const res = await axios.get("/api/v1/City/", {
                    headers: {
                        "Authorization": "8f3649541a77afc6dba538d0589424178cab4e44"
                    },
                });
                if (res.status === 200) {
                    commit("setCity", res.data.results);
                }

            } catch (error) {}
            state.isLoading = false
        },

    },
    getters: {
        city(state) {
            return state.city;
        },
        isLoading(state) {
            return state.isLoading;
        },
    },
};