import axios from "axios";
import queryString from "query-string";

export default {
    namespaced: true,
    state: () => ({
        categoryProducts: [],
        productsList: [],
        productsListForSales: [],
        productInSales: [],
        isLoading: false,
    }),
    mutations: {
        setCategoryProducts(state, categoryProducts) {
            state.categoryProducts = categoryProducts;
        },
        setProductsList(state, productsList) {
            state.productsList = productsList;
        },
        setProductsInSales(state, productInSales) {
            state.productInSales = productInSales;
        },
        setProductsListForSales(state, productsListForSales) {
            state.productsListForSales = productsListForSales;
        },

    },
    actions: {
        async getCategoryProducts({ commit, state }) {
            state.isLoading = true;
            try {
                const res = await axios.get("/api/v1/Category_Product_List", {
                    headers: {
                        "Authorization": "3e72865f43f6aea1c0fab4f77004f49f006962ff"
                    },
                });
                if (res.status === 200) {
                    commit("setCategoryProducts", res.data.results);
                }

            } catch (error) { }
            state.isLoading = false
        },
        async getProductsList({ commit, state }) {
            state.isLoading = true;
            try {
                const res = await axios.get("/api/v1/Product_Kanban/?limit=10&offset=10&page_size=100000000000", {
                    headers: {
                        "Authorization": "3e72865f43f6aea1c0fab4f77004f49f006962ff"
                    },
                });


                if (res.status === 200) {
                    commit("setProductsList", res.data.results);
                }

            } catch (error) { }
            state.isLoading = false
        },
        async getProductsForSalesList({ commit, state }, categoryId) {

            try {
                let url = "/api/v1/Product_list";
                if (categoryId) {
                    url += `?category_product=${categoryId}`;
                }

                const res = await axios.get(url, {
                    headers: {
                        "Authorization": "3e72865f43f6aea1c0fab4f77004f49f006962ff"
                    },
                });

                if (res.status === 200) {
                    commit("setProductsListForSales", res.data.results);
                }
            } catch (error) {
                // Handle error
                console.error("Error fetching products:", error);
            }

        },

        async addProduct({
            dispatch, state
        }, product) {
            state.isLoading = true;
            await axios("/api/v1/Product_list/", {
                method: "POST",
                headers: {
                    "Authorization": "8f3649541a77afc6dba538d0589424178cab4e44"
                },
                data: product,
            });
            dispatch("getProduct");
            state.isLoading = false;
        },
        async editProduct({
            dispatch, state
        }, product) {
            await axios(`/api/v1/Product_list/${product.id}/`, {
                method: "PUT",
                headers: {
                    "Authorization": "8f3649541a77afc6dba538d0589424178cab4e44"
                },
                data: product,
            });
            dispatch("getProduct");
        },
        async filterProduct({
            commit,
            state
        }, product) {
            state.isLoading = true;

            const queryParams = {};

            if (product.name) {
                queryParams.name = product.name.toString();
            }

            if (product.quantity) {
                queryParams.quantity = product.quantity.toString();
            }

            if (product.price) {
                queryParams.price = product.price.toString();
            }
            if (product.stock) {
                queryParams.stock = product.stock.toString();
            }

            if (product.manufacturer) {
                queryParams.manufacturer = product.manufacturer.toString();
            }

            if (product.expiration_date) {
                queryParams.expiration_date = product.expiration_date.toString();
            }
            if (product.code) {
                queryParams.code = product.code.toString();
            }

            if (product.unit) {
                queryParams.unit = product.unit.toString();
            }

            if (product.category_product) {
                queryParams.category_product = product.category_product.toString();
            }


            try {
                const query = queryString.stringify(queryParams);
                const res = await axios.get(`/api/v1/Product_list/?${query}`, {
                    headers: {
                        Authorization: "8f3649541a77afc6dba538d0589424178cab4e44",
                    },
                });

                if (res.status === 200) {
                    commit("setProductsList", res.data.results);
                }
            } catch (error) {
                console.error(error);
            }
            state.isLoading = false;
        },

        async getProductInSales({ commit, state }, saleId) {
            state.isLoading = true;
            try {
                const res = await axios.get(`/api/v1/ProductInSales_Kanban/?sale=${saleId}&limit=10&offset=10&page_size=100000000000`, {
                    headers: {
                        "Authorization": "3e72865f43f6aea1c0fab4f77004f49f006962ff"
                    },
                });
                if (res.status === 200) {
                    commit("setProductsInSales", res.data.results);
                }

            } catch (error) { }
            state.isLoading = false
        },
        async addProductInSales({ dispatch }, product) {
            try {
                const response = await axios.post("/api/v1/ProductInSales_Kanban/", product, {
                    headers: {
                        "Authorization": "3e72865f43f6aea1c0fab4f77004f49f006962ff"
                    }
                });
                dispatch('getProductInSales', product.sale);

                return response.data;
            } catch (error) {
                console.error("Error adding product in sales:", error);
                throw error; // Rethrow error for the calling function to handle
            }
        },

        async editProductInSales({
            dispatch
        }, product) {
            await axios(`/api/v1/ProductInSales_list/${product.id}/`, {
                method: "PUT",
                headers: {
                    "Authorization": "8f3649541a77afc6dba538d0589424178cab4e44"
                },
                data: product,
            });
            dispatch("getProductInSales");
        },


    },
    getters: {
        categoryProducts(state) {
            return state.categoryProducts;
        },
        productsList(state) {
            return state.productsList;
        },
        productInSales(state) {
            return state.productInSales;
        },
        productsListForSales(state) {
            return state.productsListForSales;
        },
        isLoading(state) {
            return state.isLoading;
        },
    },
};
