import axios from "axios";
import queryString from "query-string";

export default {
  namespaced: true,
  state: () => ({
    listLids: [],
    canbanLids: [],
    isLoading: false,
    isLoading: false,
    filteredProducts: [],
    groupedLids: [],
    status: []
  }),
  mutations: {
    setListLids(state, listLids) {
      state.listLids = listLids;
    },
    setCanbanLids(state, canbanLids) {
      state.canbanLids = canbanLids;
    },
    setFilteredProducts(state, products) {
      state.filteredProducts = products;
      state.filteredProducts = groupedLids;
    },
    setStatus(state, status) {
      state.status = status;
    },
    groupLidsByStatus(state) {
      state.groupedLids = state.status.reduce((result, status) => {
        const statusName = status.id;

        if (statusName) {
          result[statusName] = state.canbanLids.filter(lid => lid.status === status.id);
        }

        return result;
      }, {});
    },

  },
  actions: {
    async getStatus({
      commit,
      state
    }) {
      state.isLoading = true;
      try {
        const res = await axios.get("/api/v1/Status_Lids/", {
          headers: {
            "Authorization": "8f3649541a77afc6dba538d0589424178cab4e44"
          },
        });
        if (res.status === 200) {
          commit("setStatus", res.data.results);
          commit("groupLidsByStatus");
        }

      } catch (error) { }
      state.isLoading = false
    },
    async addStatus({
      dispatch
    }, lids) {
      await axios("/api/v1/Status_Lids/", {
        method: "POST",
        headers: {
          "Authorization": "8f3649541a77afc6dba538d0589424178cab4e44"
        },
        data: lids,
      });
      dispatch("getStatus");
    },
    async updateStatus({
      dispatch
    }, status) {
      await axios(`/api/v1/Status_Lids/${status.id}/`, {
        method: "PUT",
        headers: {
          "Authorization": "8f3649541a77afc6dba538d0589424178cab4e44"
        },
        data: status,
      });
      await dispatch("getStatus");
      location.reload()
    },

    async throwToTrashStatus({
      dispatch
    }, status) {
      await axios(`/api/v1/Status_Lids/${status}/`, {
        method: "DELETE",
        headers: {
          "Authorization": "8f3649541a77afc6dba538d0589424178cab4e44"
        },
        data: status,
      });

      await dispatch("getStatus");
    },
    async getListLids({
      commit,
      dispatch,
      state
    }) {
      state.isLoading = true;
      try {
        await dispatch("getStatus"); // Wait for getStatus to complete

        const res = await axios.get("/api/v1/lids_List/", {
          headers: {
            "Authorization": "8f3649541a77afc6dba538d0589424178cab4e44"
          },
        });

        if (res.status === 200) {
          commit("setListLids", res.data);


        }
      } catch (error) {
        console.error(error);
      }
      state.isLoading = false;
    },
    async getPaginationLids({
      commit,
      dispatch,
      state
    }, page) {
      state.isLoading = true;
      try {
        await dispatch("getStatus"); // Wait for getStatus to complete

        const res = await axios.get(`/api/v1/lids_List/?page=${page}`, {
          headers: {
            "Authorization": "8f3649541a77afc6dba538d0589424178cab4e44"
          },
        });

        if (res.status === 200) {
          commit("setListLids", res.data);


        }
      } catch (error) {
        console.error(error);
      }
      state.isLoading = false;
    },
    async getСanbanLids({
      commit,
      dispatch,
      state
    }) {
      state.isLoading = true;
      try {
        await dispatch("getStatus"); // Wait for getStatus to complete

        const res = await axios.get("/api/v1/lids_Kanban/?limit=10&offset=10&page_size=100000000000", {
          headers: {
            "Authorization": "8f3649541a77afc6dba538d0589424178cab4e44"
          },
        });

        if (res.status === 200) {
          commit("setCanbanLids", res.data.results);
          commit("groupLidsByStatus");

        }
      } catch (error) {
        console.error(error);
      }
      state.isLoading = false;
    },
    async filterLids({
      commit,
      state
    }, lid) {
      state.isLoading = true;

      const queryParams = {};

      if (lid.name) {
        queryParams.name = lid.name.toString();
      }

      if (lid.phone) {
        queryParams.phone = lid.phone.toString();
      }

      if (lid.comment) {
        queryParams.comment = lid.comment.toString();
      }
      if (lid.company) {
        queryParams.company = lid.company.toString();
      }

      if (lid.job) {
        queryParams.job = lid.job.toString();
      }

      if (lid.address) {
        queryParams.address = lid.address.toString();
      }
      if (lid.web) {
        queryParams.web = lid.web.toString();
      }

      if (lid.email) {
        queryParams.email = lid.email.toString();
      }



      try {
        const query = queryString.stringify(queryParams);
        const res = await axios.get(`/api/v1/lids_List/?${query}`, {
          headers: {
            Authorization: "8f3649541a77afc6dba538d0589424178cab4e44",
          },
        });

        if (res.status === 200) {
          commit("setListLids", res.data);
        }
      } catch (error) {
        console.error(error);
      }
      state.isLoading = false;
    },
    async addLids({
      dispatch
    }, lids) {
      await axios("/api/v1/lids_List/", {
        method: "POST",
        headers: {
          "Authorization": "8f3649541a77afc6dba538d0589424178cab4e44"
        },
        data: lids,
      });
      dispatch("getLids");
    },



    async updateLids({
      dispatch
    }, lid) {
      await axios(`/api/v1/lids_List/${lid.id}/`, {
        method: "PUT",
        headers: {
          "Authorization": "8f3649541a77afc6dba538d0589424178cab4e44"
        },
        data: lid,
      });
      // await dispatch("getLids");
      location.reload()
    },



    async deleteLids({
      dispatch
    }, lid) {
      await axios(`/api/v1/lids_List/${lid.id}/`, {
        method: "DELETE",
        headers: {
          "Authorization": "8f3649541a77afc6dba538d0589424178cab4e44"
        },
        data: lid,
      });
      commit("setSearchedLids", data);
      await dispatch("getLids");
    },


  },
  getters: {
    status(state) {
      return state.status;
    },
    listLids(state) {
      return state.listLids;
    },
    canbanLids(state) {
      return state.canbanLids;
    },
    searchedLids(state) {
      return state.searchedLids;
    },
    groupedLids(state) {
      return state.groupedLids;
    },
    isLoading(state) {
      return state.isLoading;
    },

    filteredProducts: (state) => state.filteredProducts,
  },
};