import axios from "axios";
import queryString from "query-string";

export default {
  namespaced: true,
  state: () => ({
    listDelivery: [],
    canbanDelivery: [],
    isLoading: false,
    isLoading: false,
    filteredProducts: [],
    groupedDelivery: [],
    status: []
  }),
  mutations: {
    setListDelivery(state, listDelivery) {
      state.listDelivery = listDelivery;
    },
    setCanbanDelivery(state, canbanDelivery) {
      state.canbanDelivery = canbanDelivery;
    },
    setFilteredProducts(state, products) {
      state.filteredProducts = products;
      state.filteredProducts = groupedDelivery;
    },
    setStatus(state, status) {
      state.status = status;
    },
    groupDeliveryByStatus(state) {
      state.groupedDelivery = state.status.reduce((result, status) => {
        const statusName = status.id;

        if (statusName) {
          result[statusName] = state.canbanDelivery.filter(order => order.status === status.id);
        }

        return result;
      }, {});
    },

  },
  actions: {
    async getStatus({
      commit,
      state
    }) {
      state.isLoading = true;
      try {
        const res = await axios.get("/api/v1/Status_Delivery/", {
          headers: {
            "Authorization": "8f3649541a77afc6dba538d0589424178cab4e44"
          },
        });
        if (res.status === 200) {
          commit("setStatus", res.data.results);
          commit("groupDeliveryByStatus");
        }

      } catch (error) {}
      state.isLoading = false
    },
    async addStatus({
      dispatch
    }, delivery) {
      await axios("/api/v1/Status_Delivery/", {
        method: "POST",
        headers: {
          "Authorization": "8f3649541a77afc6dba538d0589424178cab4e44"
        },
        data: delivery,
      });
      dispatch("getStatus");
    },
    async updateStatus({
      dispatch
    }, status) {
      await axios(`/api/v1/Status_Delivery/${status.id}/`, {
        method: "PUT",
        headers: {
          "Authorization": "8f3649541a77afc6dba538d0589424178cab4e44"
        },
        data: status,
      });
      await dispatch("getStatus");
      location.reload()
    },
    async throwToTrashStatus({
      dispatch
    }, status) {
      await axios(`/api/v1/Status_Delivery/${status}/`, {
        method: "DELETE",
        headers: {
          "Authorization": "8f3649541a77afc6dba538d0589424178cab4e44"
        },
        data: status,
      });

      await dispatch("getStatus");
    },
    async getListDelivery({
      commit,
      dispatch,
      state
    }) {
      state.isLoading = true;
      try {
        await dispatch("getStatus"); // Wait for getStatus to complete

        const res = await axios.get("/api/v1/Delivery_List/", {
          headers: {
            "Authorization": "8f3649541a77afc6dba538d0589424178cab4e44"
          },
        });

        if (res.status === 200) {
          commit("setListDelivery", res.data);


        }
      } catch (error) {
        console.error(error);
      }
      state.isLoading = false;
    },
    async getPaginationDelivery({
      commit,
      dispatch,
      state
    }, page) {
      state.isLoading = true;
      try {
        await dispatch("getStatus"); // Wait for getStatus to complete

        const res = await axios.get(`/api/v1/Delivery_List/?page=${page}`, {
          headers: {
            "Authorization": "8f3649541a77afc6dba538d0589424178cab4e44"
          },
        });

        if (res.status === 200) {
          commit("setListDelivery", res.data);


        }
      } catch (error) {
        console.error(error);
      }
      state.isLoading = false;
    },
    async getСanbanDelivery({
      commit,
      dispatch,
      state
    }) {
      state.isLoading = true;
      try {
        await dispatch("getStatus"); // Wait for getStatus to complete

        const res = await axios.get("/api/v1/Delivery_Kanban/?limit=10&offset=10&page_size=100000000000", {
          headers: {
            "Authorization": "8f3649541a77afc6dba538d0589424178cab4e44"
          },
        });

        if (res.status === 200) {
          commit("setCanbanDelivery", res.data.results);
          commit("groupDeliveryByStatus");

        }
      } catch (error) {
        console.error(error);
      }
      state.isLoading = false;
    },
    async filterDelivery({
      commit,
      state
    }, order) {
      state.isLoading = true;

      const queryParams = {};

      if (order.name) {
        queryParams.name = order.name.toString();
      }

      if (order.job) {
        queryParams.phone = order.phone.toString();
      }

      if (order.comment) {
        queryParams.comment = order.comment.toString();
      }
      if (order.company) {
        queryParams.company = order.company.toString();
      }

      if (order.job) {
        queryParams.job = order.job.toString();
      }

      if (order.address) {
        queryParams.address = order.address.toString();
      }
      if (order.web) {
        queryParams.web = order.web.toString();
      }

      if (order.email) {
        queryParams.email = order.email.toString();
      }



      try {
        const query = queryString.stringify(queryParams);
        const res = await axios.get(`/api/v1/Delivery_List/?${query}`, {
          headers: {
            Authorization: "8f3649541a77afc6dba538d0589424178cab4e44",
          },
        });

        if (res.status === 200) {
          commit("setListDelivery", res.data);
        }
      } catch (error) {
        console.error(error);
      }
      state.isLoading = false;
    },
    async addDelivery({
      dispatch
    }, delivery) {
      await axios("/api/v1/Delivery_List/", {
        method: "POST",
        headers: {
          "Authorization": "8f3649541a77afc6dba538d0589424178cab4e44"
        },
        data: delivery,
      });
      dispatch("getDelivery");
    },



    async updateDelivery({
      dispatch
    }, order) {
      await axios(`/api/v1/Delivery_List/${order.id}/`, {
        method: "PUT",
        headers: {
          "Authorization": "8f3649541a77afc6dba538d0589424178cab4e44"
        },
        data: order,
      });
      // await dispatch("getDelivery");
      location.reload()
    },



    async deleteDelivery({
      dispatch
    }, order) {
      await axios(`/api/v1/Delivery_List/${order.id}/`, {
        method: "DELETE",
        headers: {
          "Authorization": "8f3649541a77afc6dba538d0589424178cab4e44"
        },
        data: order,
      });
      commit("setSearchedDelivery", data);
      await dispatch("getDelivery");
    },


  },
  getters: {
    status(state) {
      return state.status;
    },
    listDelivery(state) {
      return state.listDelivery;
    },
    canbanDelivery(state) {
      return state.canbanDelivery;
    },
    searchedDelivery(state) {
      return state.searchedDelivery;
    },
    groupedDelivery(state) {
      return state.groupedDelivery;
    },
    isLoading(state) {
      return state.isLoading;
    },

    filteredProducts: (state) => state.filteredProducts,
  },
};