<template>
  <div v-if="isAsteriskOpen">
    <div class="modal">
      <div class="modal-title"><slot name="title" /></div>
      <slot name="top" />
      <form>
        <slot />
        <slot name="confirm"></slot>
        <slot name="about"></slot>
        <div class="modal-buttons">
          <slot name="button"></slot>
        </div>
      </form>
      <div class="modal-close" @click="$emit('closeAsterisk')">&times;</div>
    </div>
    <div class="overlay" @click="$emit('closeAsterisk')"></div>
  </div>
</template>

<script>
export default {
  emits: ["closeAsterisk"],
  props: ["isAsteriskOpen"],
};
</script>

<style></style>
