import axios from "axios";


export default {
    namespaced: true,
    state: () => ({
        branches: [],
        isLoading: false,
    }),
    mutations: {
        setBranches(state, branches) {
            state.branches = branches;
        },
    },
    actions: {
        async getBranches({
            commit,
            state
        }) {
            state.isLoading = true;
            try {
                const res = await axios.get("/api/v1/Branch/", {
                    headers: {
                        "Authorization": "8f3649541a77afc6dba538d0589424178cab4e44"
                    },
                });
                if (res.status === 200) {
                    commit("setBranches", res.data.results);
                }

            } catch (error) { }
            state.isLoading = false
        },
        async addBranches({
            dispatch
        }, branch) {
            await axios(`/api/v1/Branch/`, {
                method: "POST",
                headers: {
                    "Authorization": "8f3649541a77afc6dba538d0589424178cab4e44"
                },
                data: branch,
            });
            dispatch("getBranches");
        },
        async editBranches({
            dispatch
        }, branch) {
            await axios(`/api/v1/Branch/${branch.id}/`, {
                method: "PUT",
                headers: {
                    "Authorization": "8f3649541a77afc6dba538d0589424178cab4e44"
                },
                data: branch,
            });
            dispatch("getBranches");
        },

    },
    getters: {
        branches(state) {
            return state.branches;
        },
        isLoading(state) {
            return state.isLoading;
        },
    },
};