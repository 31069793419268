import axios from "axios";


export default {
    namespaced: true,
    state: () => ({
        istochnik: [],
        isLoading: false,
    }),
    mutations: {
        setIstochnik(state, istochnik) {
            state.istochnik = istochnik;
        },
    },
    actions: {
        async getIstochnik({
            commit,
            state
        }) {
            state.isLoading = true;
            try {
                const res = await axios.get("/api/v1/Istochnik/", {
                    headers: {
                        "Authorization": "8f3649541a77afc6dba538d0589424178cab4e44"
                    },
                });
                if (res.status === 200) {
                    commit("setIstochnik", res.data.results);
                }

            } catch (error) { }
            state.isLoading = false
        }, async addIstochnik({
            dispatch
        }, istochnik) {
            await axios("/api/v1/Istochnik/", {
                method: "POST",
                headers: {
                    "Authorization": "8f3649541a77afc6dba538d0589424178cab4e44"
                },
                data: istochnik,
            });
            dispatch("getIstochnik");
        },
        async editIstochnik({
            dispatch
        }, istochnik) {
            await axios(`/api/v1/Istochnik/${istochnik.id}/`, {
                method: "PUT",
                headers: {
                    "Authorization": "8f3649541a77afc6dba538d0589424178cab4e44"
                },
                data: istochnik,
            });
            dispatch("getIstochnik");
        },

    },
    getters: {
        istochnik(state) {
            return state.istochnik;
        },
        isLoading(state) {
            return state.isLoading;
        },
    },
};