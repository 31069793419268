import axios from "axios";
import queryString from "query-string";

export default {
    namespaced: true,
    state: () => ({
        listTask: [],
        canbanTask: [],
        lidsTask: [],
        isLoading: false,
        isLoading: false,
        filteredProducts: [],
        groupedTask: [],
        status: []
    }),
    mutations: {
        setListTask(state, listTask) {
            state.listTask = listTask;
        },
        setCanbanTask(state, canbanTask) {
            state.canbanTask = canbanTask;
        },
        setLidsTask(state, lidsTask) {
            state.lidsTask = lidsTask;
        },
        setFilteredProducts(state, products) {
            state.filteredProducts = products;
            state.filteredProducts = groupedTask;
        },
        setStatus(state, status) {
            state.status = status;
        },
        groupTaskByStatus(state) {
            state.groupedTask = state.status.reduce((result, status) => {
                const statusName = status.id;

                if (statusName) {
                    result[statusName] = state.canbanTask.filter(task => task.status === status.id);
                }

                return result;
            }, {});
        },

    },
    actions: {
        async getStatus({
            commit,
            state
        }) {
            state.isLoading = true;
            try {
                const res = await axios.get("/api/v1/Status_Task/", {
                    headers: {
                        "Authorization": "8f3649541a77afc6dba538d0589424178cab4e44"
                    },
                });
                if (res.status === 200) {
                    commit("setStatus", res.data.results);
                    commit("groupTaskByStatus");
                }

            } catch (error) { }
            state.isLoading = false
        },
        async addStatus({
            dispatch
        }, Task) {
            await axios("/api/v1/Status_Task/", {
                method: "POST",
                headers: {
                    "Authorization": "8f3649541a77afc6dba538d0589424178cab4e44"
                },
                data: Task,
            });
            dispatch("getStatus");
        },
        async updateStatus({
            dispatch
        }, status) {
            await axios(`/api/v1/Status_Task/${status.id}/`, {
                method: "PUT",
                headers: {
                    "Authorization": "8f3649541a77afc6dba538d0589424178cab4e44"
                },
                data: status,
            });
            await dispatch("getStatus");
            location.reload()
        },
        async throwToTrashStatus({
            dispatch
        }, status) {
            await axios(`/api/v1/Status_Task/${status}/`, {
                method: "DELETE",
                headers: {
                    "Authorization": "8f3649541a77afc6dba538d0589424178cab4e44"
                },
                data: status,
            });

            await dispatch("getStatus");
        },
        async getListTask({
            commit,
            dispatch,
            state
        }) {
            state.isLoading = true;
            try {
                await dispatch("getStatus"); // Wait for getStatus to complete

                const res = await axios.get("/api/v1/Task_List/", {
                    headers: {
                        "Authorization": "8f3649541a77afc6dba538d0589424178cab4e44"
                    },
                });

                if (res.status === 200) {
                    commit("setListTask", res.data);


                }
            } catch (error) {
                console.error(error);
            }
            state.isLoading = false;
        },
        async getLidsTask({
            commit,
            dispatch,
            state
        }, lid) {
            state.isLoading = true;
            try {
                await dispatch("getStatus"); // Wait for getStatus to complete

                const res = await axios.get(`/api/v1/Task_List/?lid=${lid}`, {
                    headers: {
                        "Authorization": "8f3649541a77afc6dba538d0589424178cab4e44"
                    },
                });

                if (res.status === 200) {
                    commit("setLidsTask", res.data);


                }
            } catch (error) {
                console.error(error);
            }
            state.isLoading = false;
        },
        async getPaginationTask({
            commit,
            dispatch,
            state
        }, page) {
            state.isLoading = true;
            try {
                await dispatch("getStatus"); // Wait for getStatus to complete

                const res = await axios.get(`/api/v1/Task_List/?page=${page}`, {
                    headers: {
                        "Authorization": "8f3649541a77afc6dba538d0589424178cab4e44"
                    },
                });

                if (res.status === 200) {
                    commit("setListTask", res.data);


                }
            } catch (error) {
                console.error(error);
            }
            state.isLoading = false;
        },
        async getСanbanTask({
            commit,
            dispatch,
            state
        }) {
            state.isLoading = true;
            try {
                await dispatch("getStatus"); // Wait for getStatus to complete

                const res = await axios.get("/api/v1/Task_Kanban/?limit=10&offset=10&page_size=100000000000", {
                    headers: {
                        "Authorization": "8f3649541a77afc6dba538d0589424178cab4e44"
                    },
                });

                if (res.status === 200) {
                    commit("setCanbanTask", res.data.results);
                    commit("groupTaskByStatus");

                }
            } catch (error) {
                console.error(error);
            }
            state.isLoading = false;
        },
        async filterTask({
            commit,
            state
        }, task) {
            state.isLoading = true;

            const queryParams = {};

            if (task.name) {
                queryParams.name = task.name.toString();
            }

            if (task.job) {
                queryParams.description = task.description.toString();
            }

            if (task.date_start) {
                queryParams.date_start = task.date_start.toString();
            }
            if (task.date_out) {
                queryParams.date_out = task.date_out.toString();
            }

            if (task.status) {
                queryParams.status = task.status.toString();
            }

            if (task.employee) {
                queryParams.employee = task.employee.toString();
            }




            try {
                const query = queryString.stringify(queryParams);
                const res = await axios.get(`/api/v1/Task_List/?${query}`, {
                    headers: {
                        Authorization: "8f3649541a77afc6dba538d0589424178cab4e44",
                    },
                });

                if (res.status === 200) {
                    commit("setListTask", res.data);
                }
            } catch (error) {
                console.error(error);
            }
            state.isLoading = false;
        },
        async addTask({
            dispatch
        }, Task) {
            await axios("/api/v1/Task_List/", {
                method: "POST",
                headers: {
                    "Authorization": "8f3649541a77afc6dba538d0589424178cab4e44"
                },
                data: Task,
            });
            dispatch("getTask");
        },



        async updateTask({
            dispatch
        }, task) {
            await axios(`/api/v1/Task_List/${task.id}/`, {
                method: "PUT",
                headers: {
                    "Authorization": "8f3649541a77afc6dba538d0589424178cab4e44"
                },
                data: task,
            });
            // await dispatch("getTask");
            location.reload()
        },



        async deleteTask({
            dispatch
        }, task) {
            await axios(`/api/v1/Task_List/${task.id}/`, {
                method: "DELETE",
                headers: {
                    "Authorization": "8f3649541a77afc6dba538d0589424178cab4e44"
                },
                data: task,
            });
            commit("setSearchedTask", data);
            await dispatch("getTask");
        },


    },
    getters: {
        status(state) {
            return state.status;
        },
        listTask(state) {
            return state.listTask;
        },
        canbanTask(state) {
            return state.canbanTask;
        },
        lidsTask(state) {
            return state.lidsTask;
        },
        searchedTask(state) {
            return state.searchedTask;
        },
        groupedTask(state) {
            return state.groupedTask;
        },
        isLoading(state) {
            return state.isLoading;
        },

        filteredProducts: (state) => state.filteredProducts,
    },
};