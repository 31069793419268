<template>
  <div class="sidebar">
    <ul class="sidebar-menu">
      <li class="sidebar-menu__list">
        <router-link exact :to="{ name: 'Home' }" class="sidebar-menu__link">
          <i class="icofont-chart-histogram-alt"></i>
          <p>{{ $t("desktop") }}</p>
        </router-link>
      </li>
      <li class="sidebar-menu__list">
        <router-link exact :to="{ name: 'lidi' }" class="sidebar-menu__link">
          <i class="icofont-address-book"></i>
          <p>{{ $t("leads") }}</p>
        </router-link>
      </li>
      <li class="sidebar-menu__list">
        <router-link exact :to="{ name: 'sales' }" class="sidebar-menu__link">
          <i class="icofont-bars"></i>
          <p>{{ $t("sales") }}</p>
        </router-link>
      </li>
      <li class="sidebar-menu__list dropList">
        <div
          class="sidebar-menu__link dropdown"
          @click="$emit('clickHandler', 2)"
          :class="{ activeDrop: dropdownList[2].item }"
        >
          <div class="sidebar-menu__content">
            <i class="icofont-chart-growth"></i>
            <p>
              {{ $t("references") }}
              <i class="icofont-thin-down inner-array"></i>
            </p>
          </div>
          <i class="icofont-thin-down dropdown-array"></i>
        </div>
        <ul class="dropdown-menu" :class="{ activeDrop: dropdownList[2].item }">
          <li class="dropdown-menu__list">
            <router-link
              class="dropdown-menu__link"
              :to="{ name: 'products' }"
              >{{ $t("products") }}</router-link
            >
          </li>
        </ul>
      </li>

      <li class="sidebar-menu__list">
        <router-link
          :to="{ name: 'calls' }"
          class="sidebar-menu__link notif-wrapper"
        >
          <div class="sidebar-menu__content">
            <i class="icofont-phone"></i>
            <p>{{ $t("calls") }}</p>
          </div>

          <div class="notif">5</div>
        </router-link>
      </li>

      <li class="sidebar-menu__list">
        <router-link
          :to="{ name: 'messages' }"
          class="sidebar-menu__link notif-wrapper"
        >
          <div class="sidebar-menu__content">
            <i class="icofont-ui-messaging"></i>
            <p>
              {{ $t("messages") }}
            </p>
          </div>

          <div class="notif">5</div>
        </router-link>
      </li>

      <li class="sidebar-menu__list">
        <router-link
          :to="{ name: 'notifications' }"
          class="sidebar-menu__link notif-wrapper"
        >
          <div class="sidebar-menu__content">
            <i class="icofont-notification"></i>
            <p>{{ $t("notifications") }}</p>
          </div>
          <div class="notif">3</div>
        </router-link>
      </li>
      <li class="sidebar-menu__list">
        <router-link :to="{ name: 'calendar' }" class="sidebar-menu__link">
          <i class="icofont-calendar"></i>
          <p>{{ $t("calendar") }}</p>
        </router-link>
      </li>
      <li class="sidebar-menu__list">
        <router-link :to="{ name: 'tasks' }" class="sidebar-menu__link">
          <i class="icofont-tasks"></i>
          <p>{{ $t("tasks") }}</p>
        </router-link>
      </li>
      <li class="sidebar-menu__list">
        <router-link :to="{ name: 'delivery' }" class="sidebar-menu__link">
          <i class="icofont-fast-delivery delivery"></i>
          <p>{{ $t("delivery") }}</p>
        </router-link>
      </li>

      <li class="sidebar-menu__list dropList">
        <div
          class="sidebar-menu__link dropdown"
          @click="$emit('clickHandler', 3)"
          :class="{ activeDrop: dropdownList[3].item }"
        >
          <div class="sidebar-menu__content">
            <i class="icofont-pie-chart"></i>
            <p>
              {{ $t("reports") }} <i class="icofont-thin-down inner-array"></i>
            </p>
          </div>
          <i class="icofont-thin-down dropdown-array"></i>
        </div>
        <ul class="dropdown-menu" :class="{ activeDrop: dropdownList[3].item }">
          <li class="dropdown-menu__list">
            <router-link to="/reports" class="dropdown-menu__link">{{
              $t("general_reports")
            }}</router-link>
          </li>
          <li class="dropdown-menu__list">
            <router-link class="dropdown-menu__link" to="/reports-region">{{
              $t("reports_by_regions")
            }}</router-link>
          </li>
          <li class="dropdown-menu__list">
            <router-link class="dropdown-menu__link" to="/reports-staff">{{
              $t("by_employees")
            }}</router-link>
          </li>
        </ul>
      </li>
      <li class="sidebar-menu__list dropList">
        <div
          class="sidebar-menu__link dropdown"
          @click="$emit('clickHandler', 4)"
          :class="{ activeDrop: dropdownList[4].item }"
        >
          <div class="sidebar-menu__content">
            <i class="fas fa-folder"></i>
            <p>
              {{ $t("statuses") }} <i class="icofont-thin-down inner-array"></i>
            </p>
          </div>
          <i class="icofont-thin-down dropdown-array"></i>
        </div>
        <ul class="dropdown-menu" :class="{ activeDrop: dropdownList[4].item }">
      
          
          <li class="dropdown-menu__list">
            <router-link to="/status-lids" class="dropdown-menu__link">{{
              $t("statusesLids")
            }}</router-link>
          </li>
          <li class="dropdown-menu__list">
            <router-link to="/status-sales" class="dropdown-menu__link">{{
              $t("statusesSales")
            }}</router-link>
          </li>
          <li class="dropdown-menu__list">
            <router-link to="/status-tasks" class="dropdown-menu__link">{{
              $t("statusesTasks")
            }}</router-link>
          </li>
          <li class="dropdown-menu__list">
            <router-link to="/status-delivery" class="dropdown-menu__link">{{
              $t("statusesDelivery")
            }}</router-link>
          </li>
          
       
         
        </ul>
      </li> 
      <li class="sidebar-menu__list dropList">
        <div
          class="sidebar-menu__link dropdown"
          @click="$emit('clickHandler', 5)"
          :class="{ activeDrop: dropdownList[5].item }"
        >
          <div class="sidebar-menu__content">
            <i class="icofont-question"></i>
            <p>
              {{ $t("knowledge_base") }}
              <i class="icofont-thin-down inner-array"></i>
            </p>
          </div>
          <i class="icofont-thin-down dropdown-array"></i>
        </div>
        <ul class="dropdown-menu" :class="{ activeDrop: dropdownList[5].item }">
          <li class="dropdown-menu__list">
            <a href="#" class="dropdown-menu__link">{{ $t("testing") }}</a>
          </li>
          <li class="dropdown-menu__list">
            <a class="dropdown-menu__link" href="#">{{
              $t("training_manuals")
            }}</a>
          </li>
        </ul>
      </li>
      <li class="sidebar-menu__list dropList">
        <div
          class="sidebar-menu__link dropdown"
          @click="$emit('clickHandler', 6)"
          :class="{ activeDrop: dropdownList[6].item }"
        >
          <div class="sidebar-menu__content">
            <i class="icofont-settings"></i>
            <p>
              {{ $t("settings") }} <i class="icofont-thin-down inner-array"></i>
            </p>
          </div>
          <i class="icofont-thin-down dropdown-array"></i>
        </div>
        <ul class="dropdown-menu" :class="{ activeDrop: dropdownList[6].item }">
          <li class="dropdown-menu__list">
            <router-link to="/settings" class="dropdown-menu__link">{{
              $t("basic_settings")
            }}</router-link>
          </li>
          <li class="dropdown-menu__list">
            <router-link to="/branches" class="dropdown-menu__link">{{
              $t("branches")
            }}</router-link>
          </li>
          <li class="dropdown-menu__list">
            <router-link to="/departments" class="dropdown-menu__link">{{
              $t("departments")
            }}</router-link>
          </li>
          <li class="dropdown-menu__list">
            <router-link to="/staff" class="dropdown-menu__link">{{
              $t("employee")
            }}</router-link>
          </li>
          <li class="dropdown-menu__list">
            <router-link class="dropdown-menu__link" to="/roles">{{
              $t("roles_and_rights")
            }}</router-link>
          </li>
         
          <li class="dropdown-menu__list">
            <router-link to="/sources" class="dropdown-menu__link">{{
              $t("source")
            }}</router-link>
          </li>
          <li class="dropdown-menu__list">
            <button
              class="dropdown-menu__link asterisk-btn"
              @click.prevent="isAsteriskOpen = true"
            >
              {{ $t("telephony") }}
            </button>
          </li>
          <li class="dropdown-menu__list">
            <router-link to="/social" class="dropdown-menu__link">{{
              $t("social_networks")
            }}</router-link>
          </li>
        </ul>
      </li>
    </ul>
    <div class="license" @click="isLicenseOpen = true">
      <p>{{ $t("license") }}</p>
    </div>
    <div class="license-icon" @click="isLicenseOpen = true">
      <img src="../assets/image/medal.png" alt="medal" />
    </div>
    <teleport to="body">
      <modal :isModalOpen="isLicenseOpen" @closeModal="closeModal">
        <template #top>
          <div v-if="requsetLicense">
            <div class="license-title">{{ $t("place_order") }}</div>
            <div class="modal-form license-content">
              <div class="license-content__item">{{ $t("extend_to") }}:</div>
              <div class="license-content__item">
                <div class="form-select">
                  <select class="select">
                    <option>{{ $t("period") }}</option>
                    <option>{{ $t("period") }}</option>
                    <option>{{ $t("period") }}</option>
                  </select>
                </div>
              </div>
              <div class="license-content__item">
                {{ $t("number_of_employees") }}:
              </div>
              <div class="license-content__item">
                <div class="count">
                  <i
                    class="icofont-minus-circle"
                    style="color: #336cfb; cursor: pointer"
                    @click="count--"
                  ></i>
                  {{ count }}
                  <i
                    class="icofont-plus-circle"
                    style="color: #336cfb; cursor: pointer"
                    @click="count++"
                  ></i>
                </div>
              </div>
              <div class="license-content__item">{{ $t("analytics") }}:</div>
              <div class="license-content__item">
                <div class="analitics">
                  <button
                    v-for="(btn, index) in analiticsBtns"
                    :key="btn.id"
                    class="analitics-btn"
                    :class="btn.isActive ? btn.className : null"
                    @click="toggleButtons(index)"
                  >
                    {{ $t(btn.title) }}
                  </button>
                </div>
              </div>
            </div>
            <modal-table :head="[$t('for_analytics'), $t('for_employees')]">
              <tr>
                <td v-for="b in licenseTableBody1" :key="b.id">
                  {{ prettify(b.price) }}
                </td>
              </tr>
            </modal-table>
            <modal-table :head="[$t('amount_to_be_paid')]">
              <tr>
                <td>
                  {{ prettify(2500000) }}
                </td>
              </tr>
            </modal-table>
          </div>
          <div v-else>
            <div class="license-title">Лицензия</div>
            <div class="license-content">
              <div class="license-content__item">Компания:</div>
              <div class="license-content__item">PROSPEKT</div>
              <div class="license-content__item">ИНН:</div>
              <div class="license-content__item">10000023</div>
              <div class="license-content__item">Лицензия:</div>
              <div class="license-content__item">LIMA-WERT-GHJK-43HG</div>
              <div class="license-content__item">Кол-во сотрудников:</div>
              <div class="license-content__item">15</div>
              <div class="license-content__item">Аналитика:</div>
              <div class="license-content__item">Подключенна</div>
              <div class="license-content__item">Дата окончания:</div>
              <div class="license-content__item">11.04.2022</div>
            </div>
          </div>

          <div class="modal-buttons">
            <button class="btn btn-blue" @click="requestHandle">Заказать</button
            ><button @click="closeModal" class="btn btn-red">Закрыть</button>
          </div>
        </template>
      </modal>
    </teleport>
    <teleport to="body">
      <asterisk
        @closeAsterisk="isAsteriskOpen = false"
        :isAsteriskOpen="isAsteriskOpen"
        class="asterisk-modal"
      >
        <template #title>Настройки ASTERISK </template>
        <template #top>
          <div class="modal-top">
            <div class="modal-list asterisk-btns">
              <button
                v-for="(btn, index) in topButtons"
                :key="btn.id"
                class="modal-list__link"
                :class="{ active: btn.isActive }"
                @click="handleClick(index)"
              >
                {{ $t(btn.name) }}
              </button>
            </div>
          </div>
        </template>

        <div v-if="topButtons[0].isActive" class="asterisk-box">
          <div class="asterisk-list">
            <h3 class="asterisk-title title">Подключение MYSQL</h3>
            <div class="asterisk-form top">
              <input
                type="text"
                :placeholder="$t('login')"
                class="input asterisk-input"
              />
              <input
                type="text"
                :placeholder="$t('password')"
                class="input asterisk-input"
              />
            </div>
            <div class="asterisk-form">
              <input
                type="text"
                :placeholder="$t('host')"
                class="input asterisk-input"
              />
              <input
                type="text"
                :placeholder="$t('port')"
                class="input asterisk-input"
              />
              <input
                type="text"
                :placeholder="$t('table')"
                class="input asterisk-input"
              />
            </div>
          </div>
          <div class="asterisk-list">
            <h3 class="asterisk-title title">Подключение AMI</h3>
            <div class="asterisk-form top">
              <input
                type="text"
                :placeholder="$t('login')"
                class="input asterisk-input"
              />
              <input
                type="text"
                :placeholder="$t('password')"
                class="input asterisk-input"
              />
            </div>
            <div class="asterisk-form top">
              <input
                type="text"
                :placeholder="$t('host')"
                class="input asterisk-input"
              />
              <input
                type="text"
                :placeholder="$t('port')"
                class="input asterisk-input"
              />
            </div>
          </div>
        </div>
        <div v-if="topButtons[1].isActive" class="asterisk-box">
          <div class="asterisk-list">
            <h3 class="asterisk-title title">Подключение AMI</h3>
            <div class="asterisk-form top">
              <input
                type="text"
                :placeholder="$t('title_p')"
                class="input asterisk-input"
              />
              <input
                type="text"
                :placeholder="$t('number')"
                class="input asterisk-input"
              />
            </div>
            <div class="asterisk-form top">
              <input
                type="text"
                :placeholder="$t('title_p')"
                class="input asterisk-input"
              />
              <input
                type="text"
                :placeholder="$t('number')"
                class="input asterisk-input"
              />
            </div>
          </div>
        </div>
        <div v-if="topButtons[2].isActive" class="asterisk-box">
          <div class="asterisk-list">
            <h3 class="asterisk-title title">Подключение AMI</h3>
            <div class="asterisk-form top">
              <div class="form-select asterisk-select">
                <select class="select">
                  <option>{{ $t("employee") }}</option>
                  <option>{{ $t("employee") }}</option>
                  <option>{{ $t("employee") }}</option>
                </select>
              </div>
              <input
                type="text"
                :placeholder="$t('number')"
                class="input asterisk-input"
              />
            </div>
            <div class="asterisk-form top">
              <div class="form-select asterisk-select">
                <select class="select">
                  <option>{{ $t("employee") }}</option>
                  <option>{{ $t("employee") }}</option>
                  <option>{{ $t("employee") }}</option>
                </select>
              </div>
              <input
                type="text"
                :placeholder="$t('number')"
                class="input asterisk-input"
              />
            </div>
          </div>
        </div>
        <template #button>
          <button class="btn btn-red" @click.prevent="isAsteriskOpen = false">
            {{ $t("to_close") }}
          </button>
          <button class="btn btn-blue" type="submit">{{ $t("save") }}</button>
        </template>
      </asterisk>
    </teleport>
  </div>
</template>

<script>
import Teleport from "vue2-teleport";
import Modal from "../components/Modal.vue";
import { prettify } from "../use/PrettifySum";
import TheTable from "../components/TheTable.vue";
import ModalTable from "../components/ModalTable.vue";
import Asterisk from "./Asterisk.vue";
export default {
  components: { Teleport, Modal, TheTable, ModalTable, Asterisk },
  props: ["dropdownList"],
  emits: ["clickHandler"],
  data() {
    return {
      prettify,
      isLicenseOpen: false,
      requsetLicense: false,
      count: 0,
      isAsteriskOpen: false,
      topButtons: [
        { id: 0, name: "main", isActive: true },
        { id: 1, name: "sip_number", isActive: false },
        { id: 2, name: "internal_number", isActive: false },
      ],
      analiticsBtns: [
        {
          id: 0,
          title: "turn_on",
          className: "analitics-btn__blue",
          isActive: true,
        },
        {
          id: 1,
          title: "turn_off",
          className: "analitics-btn__red",
          isActive: false,
        },
      ],

      licenseTableBody1: [
        { id: 0, price: 2000000 },
        { id: 1, price: 500000 },
      ],
    };
  },
  methods: {
    requestHandle() {
      if (!this.requsetLicense) {
        this.requsetLicense = true;
      }
    },
    closeModal() {
      this.isLicenseOpen = false;
      this.requsetLicense = false;
    },
    toggleButtons(idx) {
      this.analiticsBtns.forEach((btn) => (btn.isActive = false));
      this.analiticsBtns[idx].isActive = true;
    },
    handleClick(idx) {
      this.topButtons.forEach((btn) => (btn.isActive = false));
      this.topButtons[idx].isActive = true;
    },
  },
};
</script>
<style lang="scss" scoped>
.asterisk-btn {
  background: none !important;
  border: none !important;
}
</style>

