import axios from "axios";
import queryString from "query-string";

export default {
  namespaced: true,
  state: () => ({
    departments: [],
    isLoading: false,
  }),
  mutations: {
    setDepartments(state, departments) {
      state.departments = departments;
    },
  },
  actions: {
    async getDepartments({ commit, state }) {
      state.isLoading = true;
      try {
        const res = await axios.get("/api/v1/Department", {
          headers: {
            "Authorization": "3e72865f43f6aea1c0fab4f77004f49f006962ff"
          },
        });
        if (res.status === 200) {
          commit("setDepartments", res.data.results);
        }

      } catch (error) { }
      state.isLoading = false
    },
    async addDepartments({
      dispatch
    }, departments) {
      await axios("/api/v1/Department/", {
        method: "POST",
        headers: {
          "Authorization": "8f3649541a77afc6dba538d0589424178cab4e44"
        },
        data: departments,
      });
      dispatch("getDepartments");
    },
    async editDepartments({
      dispatch
    }, department) {
      await axios(`/api/v1/Department/${department.id}/`, {
        method: "PUT",
        headers: {
          "Authorization": "8f3649541a77afc6dba538d0589424178cab4e44"
        },
        data: department,
      });
      dispatch("getDepartments");
    },

    /*  */
  },
  getters: {
    departments(state) {
      return state.departments;
    },
    isLoading(state) {
      return state.isLoading;
    },
  },
};
