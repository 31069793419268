import axios from "axios";
import queryString from "query-string";

export default {
    namespaced: true,
    state: () => ({
        roles: [],
        permissions: [],
        isLoading: false,
    }),
    mutations: {
        setRoles(state, roles) {
            state.roles = roles;
        },
        setPermissions(state, permissions) {
            const mergedPermissions = {};

            permissions.forEach(permission => {
                const segments = permission.codename.split('_');
                segments.shift(); // Убираем первый сегмент

                const key = segments.join('_'); // Получаем оставшуюся часть после первого "_"

                if (!mergedPermissions[key]) {
                    mergedPermissions[key] = [permission];
                } else {
                    mergedPermissions[key].push(permission);
                }
            });

            state.permissions = mergedPermissions;
        },

    },
    actions: {
        async getRoles({ commit, state }) {
            state.isLoading = true;
            try {
                const res = await axios.get("/api/v1/Group/", {
                    headers: {
                        "Authorization": "3e72865f43f6aea1c0fab4f77004f49f006962ff"
                    },
                });
                if (res.status === 200) {
                    commit("setRoles", res.data.results);
                }

            } catch (error) { }
            state.isLoading = false
        },
        async getPermissions({ commit, state }) {
            state.isLoading = true;
            try {
                const res = await axios.get("api/v1/PermissionALL/?limit=10&offset=10&page_size=100000000000", {
                    headers: {
                        "Authorization": "3e72865f43f6aea1c0fab4f77004f49f006962ff"
                    },
                });
                if (res.status === 200) {
                    commit("setPermissions", res.data.results);
                }

            } catch (error) { }
            state.isLoading = false
        },
        async getRolePermissions({ commit, state }, role) {
            state.isLoading = true;
            try {
                const res = await axios.get(`api/v1/groups/${role.id}/`, {
                    headers: {
                        "Authorization": "3e72865f43f6aea1c0fab4f77004f49f006962ff"
                    },
                });
                if (res.status === 200) {
                    commit("setRoles", res.data.results);
                }

            } catch (error) { }
            state.isLoading = false
        },

        async addRoles({ dispatch }, roles, rolesPermission) {
            try {
                const response = await axios.post("/api/v1/AddGroup/", roles, {
                    headers: {
                        "Authorization": "8f3649541a77afc6dba538d0589424178cab4e44"
                    }
                });

                dispatch("getRoles");

                return response.data; // Return the response from the API call
            } catch (error) {
                console.error("Error adding roles:", error);
                throw error; // Throw the error if any
            }
        },
        async updateRoles({
            dispatch
        }, roles) {
            await axios.put(`/api/v1/AddGroup/${roles.id}/`, roles, {
                headers: {
                    "Authorization": "8f3649541a77afc6dba538d0589424178cab4e44"
                }
            });


            dispatch("getRoles");
        },

        async editRoles({
            dispatch
        }, Group) {
            await axios(`/api/v1/Group/${Group.id}/`, {
                method: "PUT",
                headers: {
                    "Authorization": "8f3649541a77afc6dba538d0589424178cab4e44"
                },
                data: Group,
            });
            dispatch("getRoles");
        },


        /*  */
    },
    getters: {
        roles(state) {
            return state.roles;
        },
        permissions(state) {
            return state.permissions;
        },
        isLoading(state) {
            return state.isLoading;
        },
    },
};
