import axios from "axios";


export default {
    namespaced: true,
    state: () => ({
        employee: [],
        userEmployee: {},
        isLoading: false,
    }),
    mutations: {
        setEmployee(state, employee) {
            state.employee = employee;
        },
        setUserEmployee(state, userEmployee) {
            state.userEmployee = userEmployee;
        },
    },
    actions: {
        async getEmployee({
            commit,
            state
        }) {
            state.isLoading = true;
            try {
                const res = await axios.get("/api/v1/Employee/", {
                    headers: {
                        "Authorization": "8f3649541a77afc6dba538d0589424178cab4e44"
                    },
                });
                if (res.status === 200) {
                    commit("setEmployee", res.data.results);
                }

            } catch (error) { }
            state.isLoading = false
        },
        async getUserEmployee({
            commit,
            state
        }) {
            state.isLoading = true;
            try {
                const user = JSON.parse(localStorage.getItem("user"));
                const userId = user.username;
                const res = await axios.get(`/api/v1/Employee/?username=${userId}`, {
                    headers: {
                        "Authorization": "8f3649541a77afc6dba538d0589424178cab4e44"
                    },
                });
                if (res.status === 200) {
                    commit("setUserEmployee", res.data.results);
                    console.log(res.data.results);
                }

            } catch (error) { }
            state.isLoading = false
        },
        async addEmployee({
            dispatch
        }, employee) {
            await axios("/api/v1/Employee/", {
                method: "POST",
                headers: {
                    "Authorization": "8f3649541a77afc6dba538d0589424178cab4e44"
                },
                data: employee,
            });
            dispatch("getEmployee");
        },
        async editEmployee({
            dispatch
        }, employee) {
            await axios(`/api/v1/Employee/${employee.id}/`, {
                method: "PUT",
                headers: {
                    "Authorization": "8f3649541a77afc6dba538d0589424178cab4e44"
                },
                data: employee,
            });
            dispatch("getEmployee");
        },
    },
    getters: {
        employee(state) {
            return state.employee;
        },
        userEmployee(state) {
            return state.userEmployee;
        },
        isLoading(state) {
            return state.isLoading;
        },
    },
};